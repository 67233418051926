import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import theme from "../theme"
import HeaderComponent from "./header.component"
import FooterComponent from "./footer.component"

const LayoutComponent = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HeaderComponent />
      <main>{children}</main>
      <FooterComponent />
    </ThemeProvider>
  )
}

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutComponent
