import React from "react"
import { Container, Grid, Box, Typography, Link } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"

import theme from "../theme"
import Logo from "../images/uxofeverything.png"

const useStyles = makeStyles(theme => ({
  white: {
    color: theme.palette.text.white,
  },
}))

const FooterComponent = () => {
  const classes = useStyles(theme)

  return (
    <footer>
      <Box
        pt={10}
        pb={10}
        style={{ background: theme.palette.background.lavenderBlush }}
      >
        <Container maxWidth="xl">
          <Grid container justify="space-between" direction="row" spacing={3}>
            <Grid item xs={12} md={4}>
              <Box pb={1}>
                <img src={Logo} alt="logo" width="auto" />
              </Box>
              <Typography
                variant="body2"
                component="p"
                style={{ textAlign: "justify" }}
              >
                Our website is a platform for companies and individual
                entrepreneurs to explore the endless possibilities of the Ux, UI
                & Design spectrum. We aim to build a strong community of
                agencies, vendors, consultants, and researchers to facilitate
                increase of reach and exchange of intelligence.
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography variant="body1" component="h6" gutterBottom>
                About
              </Typography>
              <Box pb={2}>
                <Link
                  component="button"
                  color="textPrimary"
                  underline="none"
                  onClick={() => navigate("/")}
                  variant="body2"
                >
                  Our Story
                </Link>
              </Box>
              <Box pb={2}>
                <Link
                  component="button"
                  color="textPrimary"
                  underline="none"
                  onClick={() => navigate("/")}
                  variant="body2"
                >
                  Our Writers
                </Link>
              </Box>
              <Box>
                <Link
                  component="button"
                  color="textPrimary"
                  underline="none"
                  onClick={() => navigate("/")}
                  variant="body2"
                >
                  Content Description
                </Link>
              </Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography variant="body1" component="h6" gutterBottom>
                Stay Connected
              </Typography>
              <Box pb={2}>
                <Link
                  component="button"
                  color="textPrimary"
                  underline="none"
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/UX-of-Everything-161531125647374/",
                      "_blank"
                    )
                  }
                  variant="body2"
                  target="_blank"
                >
                  Facebook
                </Link>
              </Box>
              <Box>
                <Link
                  component="button"
                  color="textPrimary"
                  underline="none"
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/ux-of-everything/",
                      "_blank"
                    )
                  }
                  variant="body2"
                  target="_blank"
                >
                  LinkedIn
                </Link>
              </Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography variant="body1" component="h6" gutterBottom>
                Our Policy
              </Typography>
              <Box pb={2}>
                <Link
                  component="button"
                  color="textPrimary"
                  underline="none"
                  onClick={() => navigate("/privacypolicy")}
                  variant="body2"
                >
                  Privacy Policy
                </Link>
              </Box>
              <Box>
                <Link
                  component="button"
                  color="textPrimary"
                  underline="none"
                  onClick={() => navigate("/termsofuse")}
                  variant="body2"
                >
                  Terms of use
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={2} pb={2} style={{ background: theme.palette.background.black }}>
        <Container maxWidth="xl">
          <Grid container direction="row" justify="center">
            <Grid item>
              <Typography
                variant="body2"
                component="p"
                className={classes.white}
              >
                © {new Date().getFullYear()}{" "}
                <Link
                  component="button"
                  className={classes.white}
                  underline="none"
                  onClick={() => window.open("https://www.uxofeverything.com")}
                  variant="body2"
                  target="_blank"
                >
                  UX of Everything
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  )
}

export default FooterComponent
