import { createMuiTheme } from "@material-ui/core/styles"

// A custom theme for this app
const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: 16,
      },
    },
    MuiButton: {
      outlined: {
        border: "1px solid #DFDFDF",
        borderRadius: "25px",
        padding: "7px 25px",
      },
    },
  },
  palette: {
    background: {
      default: "#FFF",
      black: "#000",
      transparent: "transparent",
      turquoise: "rgba(39, 219, 196, 0.26)",
      athensGray: "#EFF2F5",
      lavenderBlush: "#FFEFF5",
      apricot: "#E88679",
    },
    primary: {
      main: "#045CB6",
    },
    secondary: {
      main: "#F7E1B3",
    },
    text: {
      primary: "#000",
      secondary: "#E88679",
      white: "#FFF",
    },
  },
  typography: {
    fontFamily: "Merriweather Sans",
    body1: {
      fontFamily: "Merriweather Sans",
    },
    body2: {
      fontFamily: "Merriweather Sans",
    },
    button: {
      fontFamily: "Merriweather Sans",
      textTransform: "capitalize",
    },
    caption: {
      fontFamily: "Merriweather Sans",
    },
    h1: {
      fontFamily: "Roboto Slab",
      fontWeight: 600,
    },
    h2: {
      fontFamily: "Roboto Slab",
      fontWeight: 600,
    },
    h3: {
      fontFamily: "Roboto Slab",
      fontWeight: 600,
    },
    h4: {
      fontFamily: "Roboto Slab",
      fontWeight: 600,
    },
    h5: {
      fontFamily: "Roboto Slab",
      fontWeight: 600,
    },
    h6: {
      fontFamily: "Roboto Slab",
      fontWeight: 600,
    },
  },
})

export default theme
